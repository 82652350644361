<template>
  <div class="not-found">
    <h1>Page Not Found</h1>
  </div>  
</template>

<script>

</script>

<style scoped>

</style>