<template>
  <div class="position-absolute p-footer z-3">
    <div class="col-12 d-flex align-items-end justify-content-between sticky-bottom theme-footer">
      <router-link :to="{ name: 'DashboardHome' }" class="ms-3 mb-2 btn-home"></router-link>
      <img :src="`/img/ui-theme/` + theme.agentLogo" class="position-relative theme-footer-logo" style="height:35px"/>
    </div>
  </div>
</template>

<script>
import global from '../global'

export default {
  name: 'ViewBotNav',
  setup() {
    const {theme} = global

    return {
      theme
    }
  }
};
</script>