import { reactive } from "vue";

const theme = reactive({
  agentLogo: "mrcb-dbi-lockup-logo.svg",
  devLogo: "vista-logo.svg",
  apiUrl: "https://api.metaluxe.com.au/",
  bunnyApiUrl: "https://api.bunny.net/",
  accessKey: "ec558f34-7538-4b7e-b822-179253457e6b93932c0d-eae6-47b4-b444-1ac035b8ff7f",
  streamToken: "533537f5-9c0e-405e-ad63-354fd206265b",
  videoLibraryId: "81704",
  streamCdnUrl: "https://vz-6d49615f-1d5.b-cdn.net",
  streamApiKey: "5061fe3a-0bdf-45aa-8d0e40cc067f-89d5-40e7",
  storageApiPassword: "369509e7-f278-47ac-8104b0b1fc5d-f576-415a",
  cdnUrl: "https://vista.metaluxe.com.au",
  devFolder: "/vista",
  videosIcon: "/img/ui-theme/videos-icon.svg",
  rendersIcon: "/img/ui-theme/renders-icon.svg",
  rangeIcon: "/img/ui-theme/range-icon.svg",
  panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
  floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
  dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
  
  // top level menu
  assetsMenuOrder: [
    '1-bed',
    '2-bed',
    '3-bed',
    'lobby',
    'club vista',
    'alto & stratos 168'
  ],
  
  bed1MenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans',
    'range',
    'renders'
  ],
  bed2MenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans',
    'range',
    'renders'
  ],
  bed3MenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans',
    'range',
    'renders'
  ],
  lobbyMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans',
    'range',
    'renders'
  ],
  clubvistaMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans',
    'range',
    'renders'
  ],
  rooftopMenuOrder: [
    'videos',
    'virtual-tour',
    'balcony-views',
    'floorplans',
    'range',
    'renders'
  ],
  
  bed1Asset: {
    videos: [],
    videosName: [],
    panoramas: [ 0 ],
    panoramasName: [
      '1 Bed'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/NemKgd40i0S'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  bed2Asset: {
    videos: [],
    videosName: [],
    panoramas: [ 0 ],
    panoramasName: [
      '2 Bed'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/B3dD7AGxu'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },

  bed3Asset: {
    videos: [],
    videosName: [],
    panoramas: [ 0 ],
    panoramasName: [
      '3 Bed'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/5phsXhF-B'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },

  lobbyAsset: {
    videos: [],
    videosName: [],
    panoramas: [ 0 ],
    panoramasName: [
      'Lobby'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/7URp-vZfj'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },

  clubvistaAsset: {
    videos: [],
    videosName: [],
    panoramas: [ 0 ],
    panoramasName: [
      'Club Vista'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/FiQjeluKt'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },

  rooftopAsset: {
    videos: [],
    videosName: [],
    panoramas: [],
    panoramasName: [],
    panoramasUrl: [],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
});

export default { theme };