<template>
  <!-- Loading icon -->
  <img src="/img/ui-theme/loading.svg" class="loading-menu">
  <!-- End loading icon -->
  <video autoplay muted playsinline loop id="bgVideo" class="z-0">
    <source :src="theme.cdnUrl + '/' + theme.dashboardMp4" type="video/mp4">
  </video>
</template>

<script>
import global from '../global'

export default {
  name: 'DashboardVideo',
  setup() {
    const {theme} = global

    return {
      theme
    }
  }
};
</script>