<template>
  <!-- Top navigation -->
  <DetailTopNav :heading="this.viewType" :subheading="this.assetType" />

  <!-- Range -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1"
    v-if="viewType == 'range' || viewType == 'renders' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img
            :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName"
            class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Floorplans -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1"
    v-if="viewType == 'floorplans' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img
            :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName"
            class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Panoramas -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1"
    v-if="viewType == 'panoramas' || viewType == 'virtual-tour' || viewType == 'balcony-views' && this.assetTypesReady === true">
    <iframe :src="this.assetTypes[this.order].url" :title="this.assetTypes[this.order].title" width="100%" height="100%"
      allow="fullscreen"></iframe>
  </div>

  <OffCanvas />
</template>

<script>
import DetailTopNav from '@/components/DetailTopNav.vue'
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetViewDetail",
  props: [
    'assetType',
    'viewType',
    'order'
  ],
  components: {
    DetailTopNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      assetOrder: null,
      currentAsset: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
      currentIndex: null,
      assetNames: null,
      assetUrls: null,
      componentName: "AssetView",
      assetTypesReady: false
    }
  },
  created() {
    if (this.assetType === '1-bed') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.bed1Asset.videos
        this.assetNames = this.theme.bed1Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.bed1Asset.floorplans
        this.assetNames = this.theme.bed1Asset.floorplansName
        this.assetUrls = this.theme.bed1Asset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.bed1Asset.panoramas
        this.assetNames = this.theme.bed1Asset.panoramasName
        this.assetUrls = this.theme.bed1Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.bed1Asset.balcony
        this.assetNames = this.theme.bed1Asset.balconyName
        this.assetUrls = this.theme.bed1Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.bed1Asset.renders
        this.assetNames = this.theme.bed1Asset.rendersName
        this.assetUrls = this.theme.bed1Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.bed1Asset.range
        this.assetNames = this.theme.bed1Asset.rangeName
        this.assetUrls = this.theme.bed1Asset.rangeUrl
      }
    }
    if (this.assetType === '2-bed') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.bed2Asset.videos
        this.assetNames = this.theme.bed2Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.bed2Asset.floorplans
        this.assetNames = this.theme.bed2Asset.floorplansName
        this.assetUrls = this.theme.bed2Asset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.bed2Asset.panoramas
        this.assetNames = this.theme.bed2Asset.panoramasName
        this.assetUrls = this.theme.bed2Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.bed2Asset.balcony
        this.assetNames = this.theme.bed2Asset.balconyName
        this.assetUrls = this.theme.bed2Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.bed2Asset.renders
        this.assetNames = this.theme.bed2Asset.rendersName
        this.assetUrls = this.theme.bed2Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.bed2Asset.range
        this.assetNames = this.theme.bed2Asset.rangeName
        this.assetUrls = this.theme.bed2Asset.rangeUrl
      }
    }
    if (this.assetType === '3-bed') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.bed3Asset.videos
        this.assetNames = this.theme.bed3Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.bed3Asset.floorplans
        this.assetNames = this.theme.bed3Asset.floorplansName
        this.assetUrls = this.theme.bed3Asset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.bed3Asset.panoramas
        this.assetNames = this.theme.bed3Asset.panoramasName
        this.assetUrls = this.theme.bed3Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.bed3Asset.balcony
        this.assetNames = this.theme.bed3Asset.balconyName
        this.assetUrls = this.theme.bed3Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.bed3Asset.renders
        this.assetNames = this.theme.bed3Asset.rendersName
        this.assetUrls = this.theme.bed3Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.bed3Asset.range
        this.assetNames = this.theme.bed3Asset.rangeName
        this.assetUrls = this.theme.bed3Asset.rangeUrl
      }
    }
    if (this.assetType === 'lobby') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.lobbyAsset.videos
        this.assetNames = this.theme.lobbyAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.lobbyAsset.floorplans
        this.assetNames = this.theme.lobbyAsset.floorplansName
        this.assetUrls = this.theme.lobbyAsset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.lobbyAsset.panoramas
        this.assetNames = this.theme.lobbyAsset.panoramasName
        this.assetUrls = this.theme.lobbyAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.lobbyAsset.balcony
        this.assetNames = this.theme.lobbyAsset.balconyName
        this.assetUrls = this.theme.lobbyAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.lobbyAsset.renders
        this.assetNames = this.theme.lobbyAsset.rendersName
        this.assetUrls = this.theme.lobbyAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.lobbyAsset.range
        this.assetNames = this.theme.lobbyAsset.rangeName
        this.assetUrls = this.theme.lobbyAsset.rangeUrl
      }
    }
    if (this.assetType === 'club-vista') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.clubvistaAsset.videos
        this.assetNames = this.theme.clubvistaAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.clubvistaAsset.floorplans
        this.assetNames = this.theme.clubvistaAsset.floorplansName
        this.assetUrls = this.theme.clubvistaAsset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.clubvistaAsset.panoramas
        this.assetNames = this.theme.clubvistaAsset.panoramasName
        this.assetUrls = this.theme.clubvistaAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.clubvistaAsset.balcony
        this.assetNames = this.theme.clubvistaAsset.balconyName
        this.assetUrls = this.theme.clubvistaAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.clubvistaAsset.renders
        this.assetNames = this.theme.clubvistaAsset.rendersName
        this.assetUrls = this.theme.clubvistaAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.clubvistaAsset.range
        this.assetNames = this.theme.clubvistaAsset.rangeName
        this.assetUrls = this.theme.clubvistaAsset.rangeUrl
      }
    }
    if (this.assetType === 'alto-&-stratos-168') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.rooftopAsset.videos
        this.assetNames = this.theme.rooftopAsset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.rooftopAsset.floorplans
        this.assetNames = this.theme.rooftopAsset.floorplansName
        this.assetUrls = this.theme.rooftopAsset.floorplansUrl
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.rooftopAsset.panoramas
        this.assetNames = this.theme.rooftopAsset.panoramasName
        this.assetUrls = this.theme.rooftopAsset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.rooftopAsset.balcony
        this.assetNames = this.theme.rooftopAsset.balconyName
        this.assetUrls = this.theme.rooftopAsset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.rooftopAsset.renders
        this.assetNames = this.theme.rooftopAsset.rendersName
        this.assetUrls = this.theme.rooftopAsset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.rooftopAsset.range
        this.assetNames = this.theme.rooftopAsset.rangeName
        this.assetUrls = this.theme.rooftopAsset.rangeUrl
      }
    }

    BunnyNetService.getRenders(this.folderStructure, this.assetType)
      .then(response => {
        let j = 0;
        // Remap array

        console.log(response.data);

        const menuItems = response.data.map((item) => {
          if (this.assetUrls && this.assetUrls.length >= 1) {
            return {
              Guid: item.Guid,
              ObjectName: unslugify(item.ObjectName),
              LinkName: item.ObjectName,
              Path: item.Path,
              IsDirectory: item.IsDirectory,
              StorageZoneName: item.StorageZoneName,
              title: this.assetNames[j],
              url: this.assetUrls[j],
              id: j++
            };
          } else {
            return {
              Guid: item.Guid,
              ObjectName: unslugify(item.ObjectName),
              LinkName: item.ObjectName,
              Path: item.Path,
              IsDirectory: item.IsDirectory,
              StorageZoneName: item.StorageZoneName,
              title: unslugify(item.ObjectName.split('.').slice(0, -1).join(".")).replace("hyphen", "-"),
              url: [],
              id: j++
            };
          }
        });

        // Custom order array based on section and type
        if (this.assetOrder && this.assetOrder.length >= 1) {
          this.assetTypes = this.reorder(menuItems, this.assetOrder);
        } else {
          this.assetTypes = menuItems
        }

        this.assetTypesReady = true
      })
  },
  methods: {
    // Reorder array based on config setup
    reorder: function (data_array, real_order) {
      var i, d = {}, result = [];
      for (i = 0; i < data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for (i = 0; i < real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    },
  },
  setup() {
    const { theme } = global

    return {
      theme
    }
  }
}
</script>
